.section {
  margin: 16px;
  --system-white: #fff;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 16px;
    font-size: 13px;
    color: var(--color-text-caption);

    &-title {
      justify-self: flex-start;
    }

    &-desc {
      justify-self: flex-start;
    }
  }

  &-content {
    border-radius: 8px;
    background-color: var(--system-white);
    min-height: 30px;

    & > div:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    & > div:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

@primary-color: #1890ff;@normal-bg: #fff;@gray-bg: #f0f2f5;@background-02: rgba(255, 255, 255, 0.2);