.adm-list {
  --font-size: 15px;
  --border-top: none;
  --border-bottom: none;
  --border-inner: 0.5px rgba(189, 189, 189, 0.24) solid;
}

.adm-list-item-content-main {
  padding: 11.5px 0;
}

#root {
  background: #f8fcff;
}

.adm-nav-bar {
  background: #fff;
}

.adm-nav-bar-title {
  font-weight: 500;
  font-size: 16px;
}

.adm-search-bar {
  --border-radius: 16px;
  --background: #bdbdbd29;
}

@primary-color: #1890ff;@normal-bg: #fff;@gray-bg: #f0f2f5;@background-02: rgba(255, 255, 255, 0.2);