.wrap {
  width: 100%;
}

.img {
  width: 100%;
}

.title {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  color: #a6a6a6;
  font-weight: 500;
}

@primary-color: #1890ff;@normal-bg: #fff;@gray-bg: #f0f2f5;@background-02: rgba(255, 255, 255, 0.2);