@import './zarm.min.css';

html,
body {
  height: 100%;
  -webkit-overflow-scrolling: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#model {
  width: 90%;
  background: #f8f8f8;
  border-radius: 10px;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999;
  box-shadow: 0px 0px 18px 0px rgba(25, 25, 25, 0.26);
  text-align: end;
  box-sizing: border-box;
  padding: 10px;
}

#model > div {
  margin-bottom: 8px;
}

#wx {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

:root:root {
  --adm-color-primary: #46cdcd;
  --main-text-color: #212121;
  --weak-text-color: #757575;
  --theme-primary: #46cdcd;
  --cell-arrow-border-width: 1px;
}
